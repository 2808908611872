<template>
  <router-view />
</template>

<style lang="scss">
@import 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;1,700&display=swap';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
</style>
