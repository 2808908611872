<script setup lang="ts">
import { useStore } from 'vuex';

const store = useStore();

function prepareRedirect(
  seatNumber: number,
  seat: number,
  arena: string,
  location: string,
  stadion: string
) {
  store.dispatch('setData', { seatNumber, seat, arena, location, stadion });
}

async function allOn() {
  try {
    await fetch('/api/dali/allOn', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        switchable: true,
      }),
    });
    console.log('succeed');
  } catch (e) {
    console.log(e);
  }
}

async function allOff() {
  try {
    await fetch('/api/dali/allOff', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        switchable: false,
      }),
    });
    console.log('succeed');
  } catch (e) {
    console.log(e);
  }
}
</script>

<template>
  <div class="container">
    <section class="full-view">
      <h1 id="heading">Arena Auswahl</h1>
      <div class="background-element">
        <div class="router-element">
          <router-link
            to="{ name: 'mandant', params: { mandant: 'demo', seatId: '1', blockId: 'B', rowId: '25', id: 456 } }"
          >
            <img
              alt="alliance arena"
              src="../../public/img/arenas/AllianzArena.png"
              @click="
                prepareRedirect(
                  7,
                  2,
                  'Allianz Arena',
                  'Block B Reihe 4',
                  'AllianzArena'
                )
              "
            />
          </router-link>
        </div>

        <div class="router-element">
          <router-link to="/demo/22">
            <img
              alt="red bull arena"
              src="../../public/img/arenas/RedBullArena.png"
              @click="
                prepareRedirect(
                  8,
                  22,
                  'RedBull Arena',
                  'Block A Reihe 24',
                  'RedBullArena'
                )
              "
            />
          </router-link>
        </div>

        <div class="router-element">
          <router-link to="/demo/76">
            <img
              alt="veltins arena"
              src="../../public/img/arenas/VeltinsArena.png"
              @click="
                prepareRedirect(
                  17,
                  76,
                  'Veltins Arena',
                  'Block C Reihe 1',
                  'VeltinsArena'
                )
              "
            />
          </router-link>
        </div>

        <div class="router-element">
          <router-link to="/demo/35">
            <img
              alt="Olympiastadion"
              src="../../public/img/arenas/Olympiastadion.png"
              @click="
                prepareRedirect(
                  2,
                  35,
                  'Olympiastadion Berlin',
                  'Block D Reihe 15',
                  'Olympiastadion'
                )
              "
            />
          </router-link>
        </div>
      </div>
    </section>

    <div class="buttons">
      <button style="background: #04aa6d" @click="allOn">Alle an</button>
      <button style="background: #f44336" @click="allOff">Alle aus</button>
    </div>
  </div>
</template>

<style scoped lang="scss">
#heading {
  font-size: clamp(4em, 2em, 2em);
  position: absolute;
  top: 4vh;
}

.full-view {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  width: 98vw;
  height: 90vh;

  /* ===== Scrollbar CSS ===== */
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 10px;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

  .background-element {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 40px;
    width: 80%;
    height: clamp(40%, 50%, 75%);
    background-color: #ababab;
    overflow-x: hidden;

    .router-element {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.buttons {
  position: absolute;
  top: 80% !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  button {
    padding: 0.5rem;

    border-radius: 1rem;
    border: none;

    font-weight: bolder;
  }
}
</style>
