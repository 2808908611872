import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../public/img/arenas/AllianzArena.png'
import _imports_1 from '../../public/img/arenas/RedBullArena.png'
import _imports_2 from '../../public/img/arenas/VeltinsArena.png'
import _imports_3 from '../../public/img/arenas/Olympiastadion.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "full-view" }
const _hoisted_3 = { class: "background-element" }
const _hoisted_4 = { class: "router-element" }
const _hoisted_5 = { class: "router-element" }
const _hoisted_6 = { class: "router-element" }
const _hoisted_7 = { class: "router-element" }

import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectContextView',
  setup(__props) {

const store = useStore();

function prepareRedirect(
  seatNumber: number,
  seat: number,
  arena: string,
  location: string,
  stadion: string
) {
  store.dispatch('setData', { seatNumber, seat, arena, location, stadion });
}

async function allOn() {
  try {
    await fetch('/api/dali/allOn', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        switchable: true,
      }),
    });
    console.log('succeed');
  } catch (e) {
    console.log(e);
  }
}

async function allOff() {
  try {
    await fetch('/api/dali/allOff', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        switchable: false,
      }),
    });
    console.log('succeed');
  } catch (e) {
    console.log(e);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h1", { id: "heading" }, "Arena Auswahl", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "{ name: 'mandant', params: { mandant: 'demo', seatId: '1', blockId: 'B', rowId: '25', id: 456 } }" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                alt: "alliance arena",
                src: _imports_0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (
                prepareRedirect(
                  7,
                  2,
                  'Allianz Arena',
                  'Block B Reihe 4',
                  'AllianzArena'
                )
              ))
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_router_link, { to: "/demo/22" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                alt: "red bull arena",
                src: _imports_1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (
                prepareRedirect(
                  8,
                  22,
                  'RedBull Arena',
                  'Block A Reihe 24',
                  'RedBullArena'
                )
              ))
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_link, { to: "/demo/76" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                alt: "veltins arena",
                src: _imports_2,
                onClick: _cache[2] || (_cache[2] = ($event: any) => (
                prepareRedirect(
                  17,
                  76,
                  'Veltins Arena',
                  'Block C Reihe 1',
                  'VeltinsArena'
                )
              ))
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_router_link, { to: "/demo/35" }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                alt: "Olympiastadion",
                src: _imports_3,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (
                prepareRedirect(
                  2,
                  35,
                  'Olympiastadion Berlin',
                  'Block D Reihe 15',
                  'Olympiastadion'
                )
              ))
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", { class: "buttons" }, [
      _createElementVNode("button", {
        style: {"background":"#04aa6d"},
        onClick: allOn
      }, "Alle an"),
      _createElementVNode("button", {
        style: {"background":"#f44336"},
        onClick: allOff
      }, "Alle aus")
    ])
  ]))
}
}

})