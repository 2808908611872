import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/eisenach.jpg'


const _hoisted_1 = { class: "seat-heating-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  class: "header",
  style: {"justify-content":"center"}
}
const _hoisted_4 = { class: "icon-container" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { style: {"color":"#e7590f"} }
const _hoisted_8 = { class: "icon-container" }
const _hoisted_9 = { class: "stadium-image-container" }
const _hoisted_10 = { class: "match-info-banner" }
const _hoisted_11 = { class: "seat-info" }
const _hoisted_12 = { class: "status-ticket-container" }
const _hoisted_13 = { class: "status-row-large" }
const _hoisted_14 = { class: "status-value" }
const _hoisted_15 = { class: "heating-container" }
const _hoisted_16 = { class: "activate-heating" }
const _hoisted_17 = {
  key: 0,
  class: "mb-2 header"
}
const _hoisted_18 = {
  key: 1,
  class: "mb-2 header"
}
const _hoisted_19 = { class: "heating-options" }
const _hoisted_20 = {
  key: 0,
  class: "setup-items"
}
const _hoisted_21 = {
  key: 1,
  style: {"display":"flex","flex-wrap":"wrap","justify-content":"space-between","gap":"10px"}
}
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "price-content" }
const _hoisted_24 = { class: "time" }
const _hoisted_25 = {
  key: 0,
  class: "time-large"
}
const _hoisted_26 = {
  key: 1,
  class: "time-large"
}
const _hoisted_27 = { class: "price" }
const _hoisted_28 = { class: "price-large" }

import { computed, nextTick, onMounted, ref } from 'vue';
import { States } from '@/interfaces/States';
import { loadStripe } from '@stripe/stripe-js';
import { useRoute, useRouter } from 'vue-router';
import axios, { AxiosError } from 'axios';
import { useToast } from 'vuestic-ui';
import { io } from 'socket.io-client';
import { ChoosenProduct } from '@/interfaces/SeatData';
import VueCountdown from '@chenfengyuan/vue-countdown';
import moment from 'moment';

interface buttonItems {
  pricing: string;
  name: string;
  metadata: {
    price: number;
    time: number;
  };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const { notify } = useToast();

const chair = ref<number>();
const block = ref<string>();
const row = ref<number>();

const chairValue = ref<string>();
const blockValue = ref<string>();
const rowValue = ref<string>();

const chairError = ref<boolean>(false);
const blockError = ref<boolean>(false);
const rowError = ref<boolean>(false);
const errorKey = ref<number>(0);

const status = ref();

const choosenProduct = ref<ChoosenProduct>();
const countdown = ref<number>();
const vueCountdown = ref<any>(null);
const startCountdown = ref<boolean>(false);

const blockOptions = [
  'Block: A',
  'Block: B',
  'Block: C',
  'Block: D',
  'Block: E',
  'Block: F',
];
const seatOptions = ['Sitz: 1', 'Sitz: 2'];
const rowOptions = [
  'Reihe: 1',
  'Reihe: 2',
  'Reihe: 3',
  'Reihe: 4',
  'Reihe: 5',
  'Reihe: 6',
  'Reihe: 7',
  'Reihe: 8',
  'Reihe: 9',
  'Reihe: 10',
];

const timestamp = Date.now();
const actualDate = new Date(timestamp);

//* variable to countdown time that chair is still active
const time = ref();
//* variable if timer is running or not
const state = ref<States>(States.STOPPED);
const showSetup = ref<boolean>(false);

function notifyUser(
  title: string,
  message: string,
  color: string,
  closeable = false
) {
  notify({
    title,
    message,
    color,
    closeable,
  });
}

//* stripe payment
async function checkout(timerTime: number, price: string, productName: string) {
  try {
    const id = route.params.id;
    if (!timerTime || !price)
      throw new Error(
        'Zeit oder Preis wurde nicht für diese Option hinterlegt!'
      );
    const seatValues = {
      seat: chair.value,
      block: block.value,
      row: row.value,
    };
    const response = await fetch('/api/payments/create-checkout-session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        time: timerTime,
        pricing: price,
        mandant: route.params.mandant,
        seat: route.params.seatId,
        seatValues,
        productName,
        sessionId: id || null,
      }),
    });

    const { sessionId } = await response.json();

    //* redirect to, checkout site
    const stripe = await loadStripe(
      <string>process.env.VUE_APP_STRIPE_PUBLICKEY
    );
    await stripe?.redirectToCheckout({ sessionId });
  } catch (e) {
    notifyUser('Error', `${e}`, 'danger');
  }
}

async function getPaymentData(paymentId: string) {
  try {
    if (paymentId) {
      try {
        startCountdown.value = true;
        const response = await fetch(
          `/api/payments/payment-completed/${paymentId}`
        );
        const data = await response.json();

        block.value = data.seatValues.block.slice('Block: ');
        chair.value = data.seatValues.seat;
        row.value = data.seatValues.row;

        if (!data.isPaused && data.isValidated && paymentId) {
          state.value = States.RUNNING;
          choosenProduct.value = data;
          if (choosenProduct.value) {
            countdown.value =
              new Date(choosenProduct.value.endTime).getTime() -
              new Date().getTime();
          }
        } else if (data.isPaused && vueCountdown.value) {
          state.value = States.PAUSED;
          countdown.value = data.remainingTime;
        } else {
          notifyUser(
            'Ein Fehler ist aufgetreten!',
            'Leider ist bei der Zahlung etwas Schiefgelaufen!',
            'danger'
          );
        }
      } catch (e) {
        notifyUser('Ein Fehler ist aufgetreten!', `${e}`, 'info');
      }
    }
  } catch (error) {
    notifyUser(
      'Ein Fehler ist aufgetreten!',
      `Fehlercode für die Administratoren: ${error}`,
      'danger'
    );
  }
}
const route = useRoute();
const router = useRouter();

const socket = ref();
const buttonArray = ref<Array<buttonItems>>([]);
const isLoading = ref<boolean>(true);

onMounted(async () => {
  const paymentId = route?.params?.id;
  await getPaymentData(paymentId?.toLocaleString());

  if (!chair.value) chair.value = Number(route.params.seatId) || undefined;
  if (!block.value)
    block.value = route?.params?.blockId?.toString()?.toUpperCase();
  if (!row.value) row.value = Number(route.params.rowId) || undefined;

  if (!chair.value || !block.value || !row.value) {
    showSetup.value = true;
  }

  try {
    isLoading.value = true;
    const response = await axios.get('/api/payments/products');
    buttonArray.value = response.data;
    isLoading.value = false;
    //* Pause timer if state is paused -- need to be paused here
    if (state.value === States.PAUSED) vueCountdown?.value?.pause();
  } catch (e) {
    notifyUser('Error beim Bereitstellen der Optionen', `${e}`, 'danger');
  }

  socket.value = io(<string>process.env.VUE_APP_SERVER_URL);

  socket.value.on('products', (products: buttonItems[]) => {
    buttonArray.value = products;
  });
});

async function pauseTimer() {
  try {
    const response = await axios.post('/api/payments/pauseTimer', {
      id: String(route.params.id),
      isPaused: Boolean(state.value !== States.PAUSED), //* true, wenn pausiert wird, false beim Fortsetzen
    });

    if (response.data.success) {
      if (state.value === States.PAUSED) {
        //* Timer fortsetzen
        vueCountdown?.value?.abort();
        const paymentId = route?.params?.id;
        await getPaymentData(paymentId?.toLocaleString());
        state.value = States.RUNNING;
      } else {
        //* Timer pausieren
        vueCountdown?.value?.pause();
        state.value = States.PAUSED;
      }
    } else {
      console.error(response.status);
    }
  } catch (error) {
    console.error('Fehler bei der Anfrage:', error);
  }
}

function extractValue(inputString: string) {
  const parts = inputString.split(' ');
  return parts.slice(1).join(' ');
}

async function setupBanner() {
  if (blockValue.value && chairValue.value && rowValue.value) {
    if (blockValue.value) {
      block.value = extractValue(blockValue.value);
      blockError.value = false;
    }

    if (chairValue.value) {
      chair.value = parseInt(extractValue(chairValue.value));
      localStorage.setItem('seatId', chair.value.toString());
      chairError.value = false;
    }

    if (rowValue.value) {
      row.value = parseInt(extractValue(rowValue.value));
      rowError.value = false;
    }

    showSetup.value = false;
  } else {
    notifyUser('Fehler', 'Bitte alle Felder ausfüllen!', 'danger', true);

    if (!blockValue.value) blockError.value = true;
    if (!rowValue.value) rowError.value = true;
    if (!chairValue.value) chairError.value = true;
  }
}

const getTicketBackground = computed(() => {
  return {
    backgroundColor: state.value === 'running' ? '#e7590f' : '#007bff',
  };
});

const getBackground = computed(() => {
  return {
    backgroundColor: state.value !== 'stopped' ? '#e7590f' : '#007bff',
  };
});

function formatTime(unit: number) {
  return String(unit).padStart(2, '0');
}

async function timerEnd() {
  if (startCountdown.value) {
    const sessionId = route.params.id;
    await axios
      .get(`/api/payments/payment-completed/${sessionId}`)
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          router.push({
            path: `/demo/${chair.value}/${block.value?.toLocaleLowerCase()}/${row.value}`,
          });
          state.value = States.STOPPED;
        }
      });
    startCountdown.value = false;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_VaIcon = _resolveComponent("VaIcon")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_va_select = _resolveComponent("va-select")!
  const _component_VaSkeleton = _resolveComponent("VaSkeleton")!
  const _component_VaCard = _resolveComponent("VaCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VaCard, { class: "card" }, {
      default: _withCtx(() => [
        (status.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("div", _hoisted_4, [
            _createVNode(_component_VaIcon, {
              name: "va-arrow-left",
              class: "back-icon"
            })
          ], 512), [
            [_vShow, false]
          ]),
          (state.value !== _unref(States).RUNNING)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_5, "Sitzheizung aktivieren"))
            : (_openBlock(), _createElementBlock("h2", _hoisted_6, [
                _cache[3] || (_cache[3] = _createTextVNode(" Aktiv bis ")),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(moment)(choosenProduct.value?.endTime).format('DD.MM.YYYY HH:mm')), 1),
                _cache[4] || (_cache[4] = _createTextVNode(" Uhr "))
              ])),
          _withDirectives(_createElementVNode("div", _hoisted_8, [
            _withDirectives(_createVNode(_component_VaIcon, {
              name: "va-arrow-down",
              class: "more-icon"
            }, null, 512), [
              [_vShow, false]
            ])
          ], 512), [
            [_vShow, false]
          ])
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_9, [
          _cache[6] || (_cache[6] = _createElementVNode("img", {
            class: "stadium-image",
            src: _imports_0,
            alt: "Stadion Bild"
          }, null, -1)),
          _createElementVNode("div", _hoisted_10, [
            _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "mb-1" }, "Wartburg Stadt Eisenach : FC Eisenach", -1)),
            _createElementVNode("p", null, _toDisplayString(_unref(moment)(_unref(actualDate)).format('DD.MM.YY')) + " | 18:00 Uhr | Wartburgstadion ", 1)
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_11, [
          _cache[7] || (_cache[7] = _createElementVNode("p", null, "Platz:", -1)),
          _createElementVNode("h3", null, "Block " + _toDisplayString(block.value) + " / Reihe " + _toDisplayString(row.value) + " / Sitz " + _toDisplayString(chair.value), 1)
        ], 512), [
          [_vShow, !showSetup.value]
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_12, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "ticket-top-line" }, null, -1)),
          _createElementVNode("div", {
            class: "status-ticket",
            style: _normalizeStyle(getTicketBackground.value)
          }, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "status-row" }, [
              _createElementVNode("span", null, "Status:"),
              _createElementVNode("span", null, "Minuten verbleibend:")
            ], -1)),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("span", _hoisted_14, _toDisplayString(state.value === 'running'
                ? 'Heizt'
                : state.value === 'paused'
                  ? 'Pausiert'
                  : 'Heizt nicht'), 1),
              _createVNode(_unref(VueCountdown), {
                ref_key: "vueCountdown",
                ref: vueCountdown,
                time: countdown.value,
                class: "time-remaining",
                onEnd: timerEnd
              }, {
                default: _withCtx(({ minutes, seconds }) => [
                  _createTextVNode(_toDisplayString(formatTime(minutes)) + " : " + _toDisplayString(formatTime(seconds)), 1)
                ]),
                _: 1
              }, 8, ["time"])
            ])
          ], 4),
          _withDirectives(_createVNode(_component_va_button, {
            color: state.value === 'running' ? '#007bff' : '#e7590f',
            class: "control-button",
            onClick: pauseTimer
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(state.value === 'running' ? 'Pausieren' : 'Aktivieren'), 1)
            ]),
            _: 1
          }, 8, ["color"]), [
            [_vShow, state.value !== 'stopped']
          ])
        ], 512), [
          [_vShow, !showSetup.value]
        ]),
        _createElementVNode("div", _hoisted_15, [
          _withDirectives(_createElementVNode("div", {
            class: "cold-banner",
            style: _normalizeStyle(getBackground.value)
          }, [
            _createElementVNode("h2", null, _toDisplayString(state.value === 'stopped' ? 'Kalt?' : 'Mehr Zeit?'), 1)
          ], 4), [
            [_vShow, !showSetup.value]
          ]),
          _createElementVNode("div", _hoisted_16, [
            (showSetup.value)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_17, " Platzinformationen hier eintragen! "))
              : (_openBlock(), _createElementBlock("h1", _hoisted_18, " Jetzt Sitzheizung " + _toDisplayString(state.value === 'stopped' ? 'aktivieren' : 'verlängern') + ". ", 1)),
            _withDirectives(_createElementVNode("p", null, _toDisplayString(state.value === 'stopped'
                ? 'Für die gewünschte Zeit die Heizung des Sitzplatzes hier aktivieren und so einen komfortablen warmen Platz erhalten'
                : 'Für die gewünschte Zeit die Heizung des Sitzplatzes hier verlängern und so einen komfortablen warmen Platz behalten.'), 513), [
              [_vShow, !showSetup.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            (showSetup.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  (_openBlock(), _createBlock(_component_va_select, {
                    key: errorKey.value,
                    modelValue: blockValue.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((blockValue).value = $event)),
                    error: blockError.value,
                    "error-messages": ['Bitte Ausfüllen!'],
                    options: blockOptions,
                    placeholder: "Block",
                    "immediate-validation": ""
                  }, null, 8, ["modelValue", "error"])),
                  (_openBlock(), _createBlock(_component_va_select, {
                    key: errorKey.value,
                    modelValue: rowValue.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((rowValue).value = $event)),
                    error: rowError.value,
                    "error-messages": ['Bitte Ausfüllen!'],
                    options: rowOptions,
                    placeholder: "Reihe",
                    "immediate-validation": ""
                  }, null, 8, ["modelValue", "error"])),
                  (_openBlock(), _createBlock(_component_va_select, {
                    key: errorKey.value,
                    modelValue: chairValue.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((chairValue).value = $event)),
                    error: chairError.value,
                    "error-messages": ['Bitte Ausfüllen!'],
                    "required-mark": "",
                    required: "",
                    options: seatOptions,
                    placeholder: "Sitz",
                    "immediate-validation": ""
                  }, null, 8, ["modelValue", "error"])),
                  _createVNode(_component_va_button, { onClick: setupBanner }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("OK")
                    ])),
                    _: 1
                  })
                ]))
              : (isLoading.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1, 2, 3, 4], (number) => {
                      return _createVNode(_component_VaSkeleton, {
                        key: number,
                        animation: "wave",
                        class: "price-button",
                        style: {"max-height":"52px","width":"144px"}
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createElementVNode("div", { class: "price-content" }, [
                            _createElementVNode("div", { class: "separator-vertical" })
                          ], -1)
                        ])),
                        _: 2
                      }, 1024)
                    }), 64))
                  ]))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(buttonArray.value, (option) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: option.name,
                      class: "price-button",
                      onClick: ($event: any) => (checkout(option.metadata.time, option.pricing, option.name))
                    }, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          (state.value === 'stopped')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(option.metadata.time), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_26, "+" + _toDisplayString(option.metadata.time), 1)),
                          _cache[12] || (_cache[12] = _createElementVNode("span", { class: "time-small" }, "Minuten", -1))
                        ]),
                        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "separator-vertical" }, null, -1)),
                        _createElementVNode("div", _hoisted_27, [
                          _createElementVNode("span", _hoisted_28, _toDisplayString(option.metadata.price), 1),
                          _cache[13] || (_cache[13] = _createElementVNode("span", { class: "price-small" }, "Euro", -1))
                        ])
                      ])
                    ], 8, _hoisted_22))
                  }), 128))
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
}

})